import React, { useState } from "react";
import { Button, Flex, Image, MenuIcon, DoorArrowLeftIcon } from "@fluentui/react-northstar";
import { customLogout } from "../lib/authHelpers";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../utils/context/userContext";
import useScreenSize from "../../utils/hooks/useScreenSize";
import { HeaderProps, LocationMode } from "../../utils/types/common";
import { Feedback } from "../feedback/Feedback";

function TabletMenu({ isAdmin, inTeams, handleModeChange, themeString, mode }: HeaderProps) {
  const { t } = useTranslation();
  const { setUserData, isReportAdmin } = useUserData();
  const [showMenu, setShowMenu] = useState(false);
  const { isSmallMobile, isMobile, isTablet } = useScreenSize();
  const logoHeight = isSmallMobile ? "85%" : "100%";

  const buttonStyle = {
    height: "100%",
    width: "fit-content",
    padding: "1.25em 1em",
  };

  return (
    <>
      {isTablet && (
        <Flex column gap="gap.medium" styles={{ padding: "1em", height: "full", width: "100%" }}>
          <Flex space="between" vAlign="end" styles={{ height: "2.5em" }}>
            <Image
              id="cclogo"
              styles={{
                height: logoHeight,
                filter: themeString === "dark" || themeString === "contrast" ? "invert(100%)" : "",
                WebkitFilter: themeString === "dark" || themeString === "contrast" ? "invert(100%)" : "",
              }}
              src="./img/cccoachlogo.svg"
            />
            <Flex gap="gap.smaller" styles={{ height: "100%" }} vAlign="center">
              {!inTeams && mode !== "CyberCoach" && (
                <Button
                  styles={buttonStyle}
                  fluid
                  title="CyberCoach"
                  icon={
                    <Image
                      styles={{
                        padding: "0.3em",
                        filter: themeString === "dark" || themeString === "contrast" ? "none" : "invert(100%)",
                        WebkitFilter: themeString === "dark" || themeString === "contrast" ? "none" : "invert(100%)",
                      }}
                      avatar
                      src="img/CyberCoach_Icon_White.svg"
                    />
                  }
                  iconOnly
                  tinted={mode === "cybercoach"}
                  onClick={() => {
                    handleModeChange(LocationMode.CYBERCOACH);
                    setShowMenu(false);
                  }}
                ></Button>
              )}
              <Button
                styles={buttonStyle}
                fluid
                title="Menu"
                iconOnly
                primary={showMenu}
                icon={<MenuIcon />}
                onClick={() => setShowMenu(!showMenu)}
              />
            </Flex>
          </Flex>

          <Flex column gap="gap.medium" hAlign="center" vAlign="center" styles={{ width: "100%" }} hidden={!showMenu}>
            <Flex column gap="gap.small" hAlign="center" vAlign="center" styles={{ width: "100%" }}>
              {isAdmin && !isMobile && (
                <>
                  <Button
                    styles={{ width: "70%" }}
                    content={t("Header.configureButton")}
                    primary={mode === "configure"}
                    onClick={() => {
                      handleModeChange(LocationMode.CONFIGURE);
                      setShowMenu(!showMenu);
                    }}
                  ></Button>
                  <Button
                    styles={{ width: "70%" }}
                    content={t("Header.adminPanelButton")}
                    primary={mode === "admin"}
                    onClick={() => {
                      handleModeChange(LocationMode.ADMIN);
                      setShowMenu(!showMenu);
                    }}
                  ></Button>
                </>
              )}
              {isReportAdmin && (
                <Button
                  styles={{ width: "70%" }}
                  content={t("Header.reporting")}
                  primary={mode === "reportadmin"}
                  onClick={() => {
                    handleModeChange(LocationMode.REPORTADMIN);
                    setShowMenu(!showMenu);
                  }}
                ></Button>
              )}
              <Button
                styles={{ width: "70%" }}
                content={t("Header.profileButton")}
                primary={mode === "profile"}
                onClick={() => {
                  setUserData(null);
                  handleModeChange(LocationMode.PROFILE);
                  setShowMenu(!showMenu);
                }}
              ></Button>
              <Feedback width={"70%"} />
              {!inTeams && (
                <Button
                  styles={{ width: "70%" }}
                  icon={<DoorArrowLeftIcon />}
                  content={t("Header.logoutButton")}
                  onClick={() => customLogout()}
                ></Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
}

export default TabletMenu;
