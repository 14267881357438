import React, { useState, useEffect } from "react";
import { Dropdown, Flex, Text, Loader, Dialog, Button } from "@fluentui/react-northstar";
import { fetchFromBackend } from "../lib/makeBackendCall";
import { app, pages } from "@microsoft/teams-js";
import { LanguagePicker } from "../elements/LanguagePicker";
import { useCompanyData } from "../../utils/context/companyContext";
import { CyberCoachGroups, DropdownValue } from "../../utils/types/common";
import { useUserData } from "../../utils/context/userContext";
import { WelcomeModalUserProps } from "../../utils/types/profile";
import { useClientInfo } from "../../utils/context/clientInfoContext";

export function WelcomeModalUser({ user, accessToken, setNewUser, handleModeChange }: WelcomeModalUserProps) {
  const { userData, setUserData } = useUserData();
  const { clientInfo } = useClientInfo();
  const { companyData } = useCompanyData();

  const [userLanguage, setUserLanguage] = useState<string | undefined>(userData?.userLanguage);
  const [showDialog, setShowDialog] = useState<boolean>(true);
  const [currentGroup, setCurrentGroup] = useState<CyberCoachGroups | null | undefined>(null);
  const [cyberCoachGroups, setCyberCoachGroups] = useState<CyberCoachGroups[] | null | undefined>(null);
  const [dropDownValues, setDropDownValues] = useState<DropdownValue[]>();

  useEffect(() => {
    if (accessToken && userLanguage) {
      (async () => {
        const [data, responseStatus] = await fetchFromBackend("cybercoachGroup?" + new URLSearchParams({ lang: userLanguage }));
        if (responseStatus === 200) {
          setCyberCoachGroups(data);
        } else {
          setCyberCoachGroups(undefined);
        }
      })();
    }
  }, [accessToken, userLanguage]);

  useEffect(() => {
    if (cyberCoachGroups) {
      const dropDownOptions = cyberCoachGroups.map((cyberCoachGroup: CyberCoachGroups) => ({
        header: cyberCoachGroup.groupTranslations[0].groupName,
        key: cyberCoachGroup.groupId,
        selected: currentGroup?.groupId === cyberCoachGroup.groupId,
      }));
      setDropDownValues(dropDownOptions);
    }
  }, [cyberCoachGroups, currentGroup]);

  async function updateNewUser() {
    await fetchFromBackend("user/newUser/false", "PUT");
  }
  //Update the user with newUser=false when modal closed
  const handleCloseModal = () => {
    updateNewUser();
    setUserData({ ...userData, newUser: false, created: false });
    setNewUser(false);
    setShowDialog(false);
  };

  const handleGroupChange = (item: DropdownValue) => {
    pickGroup(item.key);
    return "Learning path changed";
  };

  async function pickGroup(groupId: string) {
    await fetchFromBackend("cybercoachGroup/" + groupId, "PUT");
    const selectedGroup = cyberCoachGroups?.find((group) => group.groupId === groupId);
    setCurrentGroup(selectedGroup);
  }

  function pickLanguage(languageCode: string) {
    (async () => {
      await fetchFromBackend("user/userLanguage/" + languageCode, "PUT");
    })();
    setUserLanguage(languageCode);
  }

  // TODO: move this to separate helper function
  async function navigateToBot() {
    //if we are within teams
    if (app.isInitialized()) {
      if (pages.currentApp.isSupported()) {
        const navPromise = pages.currentApp.navigateTo({ pageId: "conversations" });
        navPromise
          .then(() => {
            console.log("Switching to CyberCoach tab");
          })
          .catch(() => {
            /*Failed navigation*/
            console.error("Failed to switch to CyberCoach tab using pages.currentApp");
          });
      } else {
        /*Handle situation where capability isn't supported*/
        const navPromise = pages.navigateToApp({ appId: process.env.REACT_APP_TEAMS_APP_ID ?? "", pageId: "conversations" });
        navPromise
          .then(() => {
            console.log("Switching to CyberCoach tab");
          })
          .catch(() => {
            console.error("Failed to switch to CyberCoach tab");
          });
      }
    } else {
      //this is a webchat. Set mode to CyberCoach
      handleModeChange("CyberCoach");
    }
    handleCloseModal();
  }

  return (
    <Dialog
      open={showDialog}
      styles={{ maxHeight: "100%", width: "50%" }}
      content={
        <Flex column gap="gap.medium" styles={{ marginTop: "2rem" }}>
          <Flex column gap="gap.smaller">
            {/* TODO: should this content come from translations? e.g. if organization has selected something else than english for default language */}
            <Text weight="bold" content={"Hi " + user + "!"} />
            <Text
              content={
                "CyberCoach is available 24/7 and helps you with security related questions. The comprehensive set of trainings helps you stay protected both at work and during your free-time."
              }
            />
            <Text
              content={
                "Getting started is easy. Just fill in your language preference and choose the learning path your organization has set up for you. You can also modify this later at any point from your profile."
              }
            />
          </Flex>
          <Flex column gap="gap.smaller">
            <Text weight="bold" content="Bot language" />
            <LanguagePicker
              companyData={companyData}
              fluid={false}
              inverted={false}
              defaultSelected={userLanguage}
              handleLanguageChange={pickLanguage}
            />
          </Flex>
          {userLanguage && !dropDownValues && <Loader />}
          {dropDownValues && (
            <Flex column gap="gap.smaller">
              <Flex column gap="gap.smaller">
                <Text content={"Learning Path"} weight="bold" />
                <Dropdown
                  checkable
                  items={dropDownValues}
                  placeholder={currentGroup ? currentGroup.groupTranslations[0].groupName : "Choose Learning Path"}
                  getA11ySelectionMessage={{
                    onAdd: (item) => handleGroupChange(item as DropdownValue),
                  }}
                />
              </Flex>
              {currentGroup && (
                <Flex column>
                  <Text content={"Description of Learning Path"} weight="bold" />
                  <Text content={currentGroup.groupTranslations[0].groupDesc} />
                </Flex>
              )}
            </Flex>
          )}
          {currentGroup && dropDownValues && (
            <Flex column gap="gap.medium">
              {/* TODO: should this come from translations? */}
              <Text>
                You are now ready to use CyberCoach. When you are ready to take your first training, visit the chat tab if you are using Microsoft
                Teams or the messages tab in your Slack application if that is your platform.
              </Text>
              <Flex gap="gap.small">
                <Button primary content={"Explore Profile"} onClick={() => handleCloseModal()} />
                {clientInfo?.client !== "Teams" && <Button primary content={"Start learning"} onClick={() => navigateToBot()} />}
              </Flex>
            </Flex>
          )}
        </Flex>
      }
      header={<Text content={"Welcome to CyberCoach"} size="larger" />}
    />
  );
}
