import React from "react";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { ExportToExcelProps } from "../../utils/types/common";

function ExportToExcel({ apiData, fileName, innerRef, id }: ExportToExcelProps) {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportFile = (apiData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return <button hidden id={id} ref={innerRef} onClick={() => exportFile(apiData, fileName)} />;
}

export default ExportToExcel;
