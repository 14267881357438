import React, { useEffect, useState } from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme, teamsDarkTheme, teamsHighContrastTheme } from "@fluentui/react-northstar";
import { Route, Routes } from "react-router-dom";
import Tab from "./Tab";
import "./App.css";
import { SignInPage } from "./views/SignIn/SignInPage";
import { LoaderHelper } from "./views/LoaderHelper";
import { Providers, ProviderState, SimpleProvider } from "@microsoft/mgt-react";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { app } from "@microsoft/teams-js";
import { msalConfig } from "./lib/msalconfig";
import { getSlackToken, getSlackuserInfo } from "./lib/slackAuth";
import { EnterLicense } from "./welcomeModal/EnterLicense";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

import { getWebchatUserProfile } from "./app_utils_temp";

import { customLogin, getServerSideToken } from "./lib/microsoftAuth";
import { reactPlugin } from "./lib/appInsights";
import { AppInsightsErrorBoundary, AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
  useLoginState,
  customLogout,
  CLIENT_MICROSOFT_WEB,
  CLIENT_SLACK,
  CLIENT_TEAMS,
  CLIENT_WEBCHAT,
  getClient,
  checkIfUserIsCyberCoachAdmin,
} from "./lib/authHelpers";
import UserProvider from "../utils/context/userContext";
import CompanyConfigurationProvider from "../utils/context/companyConfigurationContext";
import CompanyProvider from "../utils/context/companyContext";
import { fetchFromBackend } from "./lib/makeBackendCall";
import { useClientInfo } from "../utils/context/clientInfoContext";

//initialize translation
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  //0=Loading, 1=Logged Out, 2=Logged IN
  const [loginState] = useLoginState();
  const [userInfo, setUserInfo] = useState<any>(null);
  const [adminUser, setAdminUser] = useState<boolean>(false);
  const [themeString, setThemeString] = useState<string>("");
  const [theme, setTheme] = useState(teamsTheme);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [tenantValid, setTenantValid] = useState<boolean | null>(null);
  const [backendAccess, setBackendAccess] = useState<boolean>(true);
  const { clientInfo, setClientInfo } = useClientInfo();

  //track the loginstate with this one regardless of provider
  const [compositeLoginState, setCompositeLoginState] = useState<ProviderState | undefined>(loginState);

  useEffect(() => {
    setCompositeLoginState(loginState);
  }, [loginState]);

  useEffect(() => {
    async function checkAndSetClient() {
      const client = await getClient();
      setClientInfo({ ...clientInfo, client: client });
    }
    !clientInfo?.client && checkAndSetClient();
  }, [clientInfo?.client]);

  useEffect(() => {
    async function initializeProvider() {
      if (clientInfo?.client === CLIENT_TEAMS) {
        try {
          if (!app.isInitialized()) {
            await app.initialize();
          }
          app.notifyAppLoaded();
          const context = await app.getContext();
          setThemeString(context.app.theme);
          app.registerOnThemeChangeHandler(function (theme) {
            setThemeString(theme);
          });

          Providers.globalProvider = new SimpleProvider(getServerSideToken, customLogin, customLogout);

          await Providers.globalProvider.getAccessToken();

          //if we are here we are authenticated
          Providers.globalProvider.setState(ProviderState.SignedIn);
          app.notifySuccess();
        } catch (error) {
          //consents might be missing, login flow must be initialized
          app.notifyExpectedFailure({ reason: app.ExpectedFailureReason.PermissionError, message: "Consent needed" });
          //setting state to signedout will render login page
          Providers.globalProvider.setState(ProviderState.SignedOut);
        }
      } else if (clientInfo?.client !== CLIENT_TEAMS) {
        addBackGroundColor("#eeeeee");
        if (clientInfo?.client === "N/A") {
          //just mark as as logged out. Need to pass through login page
          setCompositeLoginState(ProviderState.SignedOut);
        }
        //Microsoft authentication . We can create the provider also try to login
        else if (clientInfo?.client === CLIENT_MICROSOFT_WEB) {
          try {
            Providers.globalProvider = new Msal2Provider(msalConfig);
          } catch {
            Providers.globalProvider.setState(ProviderState.SignedOut);
            localStorage.removeItem("ccclient");
            setClientInfo({ ...clientInfo, client: "N/A" });
          }
        }
      }
    }
    clientInfo?.client && initializeProvider();
  }, [clientInfo?.client]);

  // nothing to do with logging in
  useEffect(() => {
    switch (themeString) {
      case "dark":
        addBackGroundColor("#2D2C2C");
        setTheme(teamsDarkTheme);
        break;
      case "contrast":
        addBackGroundColor("#2D2C2C");
        setTheme(teamsHighContrastTheme);
        break;
      default:
        addBackGroundColor("#eeeeee");
        setTheme(teamsTheme);
    }
  }, [themeString]);

  // nothing to do with logging in
  const addBackGroundColor = (color: string) => {
    document.body.style.background = color;
    setBackgroundColor(color);
  };

  useEffect(() => {
    async function getProfileInfo() {
      try {
        const me = await Providers.globalProvider.graph.api("/me").get();
        setUserInfo(me);
        try {
          const photo = await Providers.globalProvider.graph.api("/me/photo/$value").get();
          setUserInfo({ ...me, userPicture: URL.createObjectURL(photo) });
        } catch {
          console.log("Failed to fetch user picture. Showing initials instead");
        }
        setTenantValid(await validateTenant());
        setAdminUser(await checkIfUserIsCyberCoachAdmin());
      } catch (err) {
        //Failed to get user information or validate the user organization
        setCompositeLoginState(ProviderState.SignedOut);
      }
    }
    async function getSlackProfileInfo() {
      try {
        const me = await getSlackuserInfo();
        setUserInfo(me);
        setTenantValid(await validateTenant());
        setAdminUser(await checkIfUserIsCyberCoachAdmin());
        setCompositeLoginState(ProviderState.SignedIn);
      } catch (err) {
        //Failed to get user information or validate the user organization
        setCompositeLoginState(ProviderState.SignedOut);
      }
    }

    async function getWebchatProfileInfo() {
      try {
        const profile = await getWebchatUserProfile();
        setUserInfo(profile);
        setTenantValid(await validateTenant());
        setAdminUser(await checkIfUserIsCyberCoachAdmin());
        setCompositeLoginState(ProviderState.SignedIn);
      } catch (err) {
        console.log(err);
        setCompositeLoginState(ProviderState.SignedOut);
      }
    }

    if (clientInfo?.client === CLIENT_WEBCHAT && tenantValid === null) {
      getWebchatProfileInfo();
    } else if (compositeLoginState == ProviderState.SignedIn && clientInfo?.client == CLIENT_SLACK && tenantValid === null) {
      getSlackProfileInfo();
    } else if (compositeLoginState == ProviderState.SignedIn && tenantValid === null) {
      getProfileInfo();
    }
  }, [compositeLoginState, clientInfo?.client, tenantValid]);

  async function validateTenant() {
    try {
      const [data, responseCode] = await fetchFromBackend("license/validate");
      if (responseCode === 200) {
        return data.valid;
      } else if (responseCode === 500) {
        setBackendAccess(false);
        throw new Error("Connection to database couldn't be extablished");
      } else {
        throw new Error("User organization doesn't have a valid license");
      }
    } catch (err) {
      setCompositeLoginState(ProviderState.SignedOut);
      return false;
    }
  }

  //For Slack
  //After authenticating we get a temporary token as a query param. This needs to be exchanged for an actual Token
  useEffect(() => {
    async function getToken() {
      const queryParams = new URLSearchParams(window.location.search);
      //if we have a team_id it means we should start again from login
      if (!queryParams.get("team_id")) {
        try {
          setCompositeLoginState(ProviderState.Loading);
          const slackToken = await getSlackToken(queryParams.get("code"));
          setClientInfo((clientInfo) => ({ ...clientInfo, queryTeamId: slackToken.queryTeamId }));
          setCompositeLoginState(ProviderState.SignedIn);
        } catch (err) {
          //Failed to authenticate user. We need new Slack Token
          setCompositeLoginState(ProviderState.SignedOut);
        }
      } else {
        setCompositeLoginState(ProviderState.SignedOut);
      }
    }
    //get slack token
    clientInfo?.client == CLIENT_SLACK && getToken();
  }, [clientInfo?.client]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => (
          <LoaderHelper
            header="Something went wrong:("
            text="We apologize for this. Try refreshing the page and if that doesn't help reach out to the CyberCoach Team"
          />
        )}
        appInsights={reactPlugin}
      >
        <Provider theme={theme} styles={{ backgroundColor: backgroundColor, width: "100%", height: "inherit" }}>
          {tenantValid === false && backendAccess && (
            <>
              <EnterLicense teamName={userInfo?.teamName} queryTeamId={clientInfo?.queryTeamId} />
              <LoaderHelper header="Organization not recognized" text="Your organization is not recognized by CyberCoach" />
            </>
          )}
          {!backendAccess && <LoaderHelper header="Server error" text="Connection to server couldn't be established" />}
          {(!compositeLoginState || compositeLoginState == ProviderState.SignedOut) && backendAccess && clientInfo?.client && <SignInPage />}
          {(compositeLoginState == ProviderState.Loading || !ProviderState || !userInfo || !tenantValid) &&
            compositeLoginState != ProviderState.SignedOut && (
              <LoaderHelper header="Authenticating" text="Signing you in to CyberCoach" showLoader={true} />
            )}
          {compositeLoginState == ProviderState.SignedIn && userInfo && tenantValid && (
            <Routes>
              <Route
                path="*"
                element={
                  <CompanyProvider>
                    <UserProvider>
                      <CompanyConfigurationProvider>
                        <Tab providerAdmin={adminUser} inTeams={clientInfo?.client == CLIENT_TEAMS} userInfo={userInfo} themeString={themeString} />
                      </CompanyConfigurationProvider>
                    </UserProvider>
                  </CompanyProvider>
                }
              />
            </Routes>
          )}
        </Provider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}
