import React, { useContext, useState, createContext } from "react";

export interface IClientInfo {
  client?: string | null;
  queryTeamId?: string;
}

export type ClientInfoContextType = {
  clientInfo: IClientInfo | undefined | null;
  setClientInfo: React.Dispatch<React.SetStateAction<IClientInfo | undefined | null>>;
};

export const ClientInfoContext = createContext<ClientInfoContextType | undefined>(undefined);

export const useClientInfo = () => {
  const context = useContext(ClientInfoContext);
  if (!context) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }
  return context;
};

const ClientInfoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [clientInfo, setClientInfo] = useState<IClientInfo | null | undefined>(null);

  return <ClientInfoContext.Provider value={{ clientInfo, setClientInfo }}>{children}</ClientInfoContext.Provider>;
};

export default ClientInfoProvider;
