import { fetchFromBackend } from "./makeBackendCall";

export function getSlackToken(temptoken) {
  return new Promise((resolve, reject) => {
    fetch("/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        temptoken: temptoken,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json();
        }
      })
      .then((responseJson) => {
        if (responseJson.error) {
          //we don't have a token need to send user through login flow
          window.history.replaceState(null, "CyberCoach Dashboard", window.location.origin);
          reject(responseJson.error);
        } else {
          //hide one-time slack auth code
          window.history.replaceState(null, "CyberCoach Dashboard", window.location.origin);
          const token = responseJson;
          resolve(token);
        }
      });
  });
}

//get user information from slack
export async function getSlackuserInfo() {
  const [data] = await fetchFromBackend("slack/userInfo");
  const userJson = {
    displayName: data.name,
    userPicture: data.picture,
    tid: data["https://slack.com/team_id"],
    id: data.sub,
    teamName: data["https://slack.com/team_name"],
  };
  return userJson;
}
