import { useState, useEffect } from "react";
import { Providers } from "@microsoft/mgt-react";
import { app } from "@microsoft/teams-js";

/**
 * Variables for determining client
 */
export const CLIENT_WEBCHAT = "WebChat";
export const CLIENT_TEAMS = "Teams";
export const CLIENT_MICROSOFT_WEB = "MSWeb";
export const CLIENT_SLACK = "Slack";

/**
 * Hook for tracking the loginstate of a microsoft provider
 * @returns Provider.LoginState
 */
export function useLoginState() {
  const [loginState, setLoginState] = useState();
  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      if (provider) {
        setLoginState(provider.state);
      }
    };
    Providers.onProviderUpdated(updateState);
    updateState();
    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    };
  }, []);

  return [loginState];
}

/**
 * Returns the client of the user.
 * @returns {Promise<string | null>} client
 */
export async function getClient() {
  //Check if we are in teams
  if (await checkIfMicrosoftApp()) {
    return CLIENT_TEAMS;
  }
  //now check if we already know client based on value in localstorage
  if (localStorage.getItem("ccclient")) {
    const lsv = localStorage.getItem("ccclient");
    return lsv;
  }
  //further check if this is webchat
  if (await checkIfWebchat()) {
    return CLIENT_WEBCHAT;
  }
  //we have no idea which client. Return "N/A" client will be determined later based on what login method user chooses
  else {
    console.log("Client not yet known");
    return "N/A";
  }
}

/**
 * Logout function that works across all login methods
 */
export async function customLogout() {
  localStorage.removeItem("ccclient");
  if (await checkIfWebchat()) {
    await fetch("/account/logout");
    window.location = "/";
    return;
  }
  if (Providers.globalProvider) {
    fetch("/session", {
      method: "DELETE",
    }).then(Providers.globalProvider.logout());
  } else {
    fetch("/session", {
      method: "DELETE",
    }).then(window.location.reload());
  }
}

/**
 * Checks if the client is a Microsoft app (teams/outlook)
 * @returns {Promise<boolean>}
 */

async function checkIfMicrosoftApp() {
  try {
    //first check if app is already initialized
    if (app.isInitialized()) {
      return true;
    } else {
      //not initialized. Let's see if we can initialize it
      await app.initialize();
      //if app is now initialized then we are in teams/outlook
      return app.isInitialized();
    }
  } catch (error) {
    return false;
  }
}

/**
 * Checks if this is a webchat client
 * @returns {Promise<boolean>}
 */
async function checkIfWebchat() {
  const res = await fetch("/account");
  console.log(res);
  if (res.status === 200) {
    const json = await res.json();
    return json.isAuthenticated || false;
  }
  return false;
}

/**
 * Helper function to determine if user is a cybercoach admin
 * @returns {Promise<boolean>}
 */
export async function checkIfUserIsCyberCoachAdmin() {
  const request = await fetch("cyberCoachAdmin");
  const response = await request.json();
  return request.status === 200 && response.isAdmin;
}
