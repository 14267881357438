import { createTheme } from "@fluentui/react";

export function isDarkTheme(themeString: string) {
  return themeString === "dark" || themeString === "contrast";
}

export const chartTheme = createTheme({
  palette: {
    themePrimary: "#464eb8",
    themeLighterAlt: "#030307",
    themeLighter: "#0b0c1d",
    themeLight: "#151737",
    themeTertiary: "#2a2e6e",
    themeSecondary: "#3d44a2",
    themeDarkAlt: "#545bbf",
    themeDark: "#6a70c9",
    themeDarker: "#8c91d7",
    neutralLighterAlt: "#323130",
    neutralLighter: "#31302f",
    neutralLight: "#2f2e2d",
    neutralQuaternaryAlt: "#2c2b2a",
    neutralQuaternary: "#2a2928",
    neutralTertiaryAlt: "#282726",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralSecondaryAlt: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#323130",
  },
});
