import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ClosePopup from "./auth/ClosePopup";
import ConsentPopup from "./auth/ConsentPopup";
import BlankAuthEnd from "./auth/BlankAuthEnd";
import ClientInfoContext from "./utils/context/clientInfoContext";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Suspense
        fallback={
          <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <div className="suspenseLoader" />
            <h1>Loading</h1>
          </div>
        }
      >
        <Routes>
          <Route path="/auth-start" element={<ConsentPopup />} />
          <Route path="/auth-end" element={<ClosePopup />} />
          <Route path="/blank-auth-end" element={<BlankAuthEnd />} />
          <Route
            path="/*"
            element={
              <ClientInfoContext>
                <App />
              </ClientInfoContext>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
