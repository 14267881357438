import React, { useState, useEffect, useCallback } from "react";
import { Profile } from "./profile/Profile";
import { CyberCoachChat } from "./views/CyberCoachChat";
import { LoaderHelper } from "./views/LoaderHelper";
import { fetchFromBackend } from "./lib/makeBackendCall";
import Header from "./header/Header";
import { Flex } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { getUserGroups } from "./lib/userGroups";
import { useLocation, useSearchParams } from "react-router-dom";
import { useUserData } from "../utils/context/userContext";
import { useCompanyConfigurationData } from "../utils/context/companyConfigurationContext";
import { useCompanyData } from "../utils/context/companyContext";
import AlertProvider from "../utils/context/alertContext";
import NotificationAlert from "./elements/Notification";
import { LocationMode, TabProps, UpdateUserGroupsFunction, UserGroup } from "../utils/types/common";
import hasMatchingArrayContent from "../utils/helpers/hasEqualIds";
import { useClientInfo } from "../utils/context/clientInfoContext";

const Dashmin = React.lazy(() => import("./dashmin/dashboard/Dashmin"));
const ConfigPage = React.lazy(() => import("./dashmin/configure/ConfigPage"));
const ReportAdminPage = React.lazy(() => import("./reporting/ReportAdminPage"));

export default function Tab({ providerAdmin, inTeams, userInfo, themeString }: TabProps) {
  const { i18n } = useTranslation();
  const { clientInfo } = useClientInfo();
  const { userData, setUserData, setIsReportAdmin } = useUserData();
  const { companyConfiguration, setCompanyConfiguration } = useCompanyConfigurationData();
  const { companyData, setCompanyData } = useCompanyData();

  const isTrial = process.env.REACT_APP_ENV_TYPE === "trial";
  const isAdmin = isTrial || providerAdmin || (userData && userData.userType === "admin");

  //get location (URL path)
  const location = useLocation();
  //get searchParams
  const [searchParams] = useSearchParams();
  //clear location to not cause later issues
  window.history.replaceState(null, "CyberCoach Dashboard", window.location.origin);

  //managing mode
  const modeFromURL = location?.pathname?.startsWith("/") ? location.pathname.toLowerCase().substring(1) : undefined;

  const isKnownLocation =
    modeFromURL === LocationMode.PROFILE ||
    modeFromURL === LocationMode.ADMIN ||
    modeFromURL === LocationMode.CONFIGURE ||
    modeFromURL === LocationMode.CYBERCOACH ||
    modeFromURL === LocationMode.REPORTADMIN;
  const [mode, setMode] = useState<string>(isKnownLocation ? modeFromURL : "");
  //use this for sending commands when changing modes such as start training
  const initialBotCommand = searchParams.get("training") ? "launch training " + searchParams.get("training") : "";
  const [botCommand, setBotCommand] = useState<string>(initialBotCommand);

  useEffect(() => {
    async function getView() {
      const viewData = await fetch("currentview");
      const currentView = await viewData.json();
      setMode(currentView.view);
    }
    if (mode === "") {
      getView();
    } else if (modeFromURL && modeFromURL === mode) {
      handleModeChange(modeFromURL);
    }
  }, [mode, modeFromURL]);

  const handleModeChange = (mode: string, command?: string) => {
    // 👇️ take parameter passed from Child component
    async function setView() {
      if (mode) {
        await fetch("/currentview/" + mode, {
          method: "POST",
        });
        setMode(mode);
        if (command) {
          setBotCommand(command);
        }
      }
    }
    setView();
  };

  const updateUserGroups: UpdateUserGroupsFunction = useCallback(
    async (data) => {
      const userGroups = await getUserGroups(clientInfo);
      if (userGroups) {
        const providerGroups = userGroups.map((group: UserGroup) => group.id);
        if (!hasMatchingArrayContent(data.userGroups, providerGroups)) {
          const [responseStatus] = await fetchFromBackend("user/userGroups", "POST", { userGroups: providerGroups });
          if (responseStatus === 200) {
            setUserData({ ...data, userGroups: providerGroups });
          }
        }
      }
    },
    [clientInfo, setUserData]
  );

  // company data
  useEffect(() => {
    (async () => {
      const [data, responseStatus] = await fetchFromBackend("company");
      if (responseStatus === 200) {
        setCompanyData(data);
      } else {
        setCompanyData(undefined);
      }
    })();
  }, [setCompanyData]);

  //config for company
  useEffect(() => {
    if (companyData && !companyConfiguration) {
      (async () => {
        const [data, statusCode] = await fetchFromBackend("configuration?confGroups=companyName;features");
        if (statusCode === 200) {
          const configuration = {
            showAssessments: data.features.enabledFeatures.includes("assessment"),
            companyName: data.companyName,
          };
          setCompanyConfiguration(configuration);
        }
      })();
    }
  }, [companyData, companyConfiguration, setCompanyConfiguration]);

  //cybercoach user data
  useEffect(() => {
    if (companyData && !userData && companyConfiguration) {
      (async () => {
        const [data, statusCode] = await fetchFromBackend("user/fullProfile");
        if (statusCode === 200) {
          setUserData(data);
          const showReportAdminFeature = data?.reportAdminGroups?.length > 0 && !isAdmin && companyData?.allowDataSynch === 1;
          setIsReportAdmin(showReportAdminFeature);
          //en is default language. No need to change if user has english
          if (data.userLanguage && data.userLanguage !== "en") {
            i18n.changeLanguage(data.userLanguage);
          }
          //check if groups (userGroups) that user belongs to have changed
          updateUserGroups(data);
        } else {
          setUserData(undefined);
        }
      })();
    }
  }, [companyData, userData, i18n, updateUserGroups, companyConfiguration, setUserData]);

  const loaderContent = () => {
    if (companyData === undefined) {
      return (
        <LoaderHelper
          header="Organization not found"
          text="Failed to retrieve information about your organization. If you are an admin, please make sure that the license code has been correctly entered."
        />
      );
    }
    if (companyData === null || companyConfiguration === null) {
      return <LoaderHelper header="Loading" text="Getting information about your organization" showLoader={true} />;
    }
    if (companyData && companyConfiguration && userData === undefined) {
      return <LoaderHelper header="Error" text="Failed to load CyberCoach Profile" />;
    }
    if (companyData && companyConfiguration && userData === null && mode.toLowerCase() === LocationMode.PROFILE) {
      return <LoaderHelper header="Loading" text="Retrieving your CyberCoach profile data" showLoader={true} />;
    }
    return <></>;
  };

  const showAdmin = companyData && companyConfiguration && mode.toLowerCase() === LocationMode.ADMIN;
  const showConfig = companyData && companyConfiguration && mode.toLowerCase() === LocationMode.CONFIGURE;
  const showReportAdmin = companyData && companyConfiguration && mode.toLowerCase() === LocationMode.REPORTADMIN;
  const showProfile = companyData && companyConfiguration && userData && userInfo && mode.toLowerCase() === LocationMode.PROFILE;

  return (
    <AlertProvider>
      <Flex column styles={{ height: "100%" }}>
        <Header inTeams={inTeams} themeString={themeString} isAdmin={isAdmin} handleModeChange={handleModeChange} mode={mode.toLowerCase()} />
        {/*TODO Remove accesstoken props here too*/}
        {/* show loader when user in not in cybercoach (bot) page */}
        {mode.toLowerCase() !== LocationMode.CYBERCOACH ? loaderContent() : <></>}
        {showAdmin && <Dashmin themeString={themeString} />}
        {showConfig && <ConfigPage accessToken={"accessToken"} />}
        {showProfile && (
          <Profile userName={userInfo.displayName} userImg={userInfo.userPicture} accessToken={"accessToken"} handleModeChange={handleModeChange} />
        )}
        {showReportAdmin && <ReportAdminPage />}
        {/** Chat componet should always be rendered and not re-rendered based on state variables. Visibility is managed using css in the component. This ensures that history and functionality of bot is preserved when switching tabs */}
        <CyberCoachChat
          inTeams={inTeams}
          show={mode.toLowerCase() === LocationMode.CYBERCOACH}
          accessToken={"accessToken"}
          userName={userInfo.displayName}
          command={botCommand}
          themeString={themeString}
        />
        <NotificationAlert />
      </Flex>
    </AlertProvider>
  );
}
