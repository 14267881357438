import React, { useMemo, useState } from "react";
import "./Profile.css";
import { fetchFromBackend } from "../lib/makeBackendCall";
import { WelcomeModalUser } from "../welcomeModal/WelcomeModalUser";
import { LoaderHelper } from "../views/LoaderHelper";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../utils/context/userContext";
import { useCompanyConfigurationData } from "../../utils/context/companyConfigurationContext";
import { useCompanyData } from "../../utils/context/companyContext";
import { LearningInfo } from "../../utils/types/common";
import { ProfileProps } from "../../utils/types/profile";
import { TrainingsCompleted } from "../../utils/types/user";
import { Assessments } from "./cards/Assessments";
import { Badges } from "./cards/Badges/Badges";
import Trainings from "./cards/CompletedTrainings/Trainings";
import { Progress } from "./cards/Progress/Progress";
import UserCard from "./cards/UserCard";
import { AssessmentsItem } from "../../utils/types/assessments";

export function Profile({ userName, userImg, accessToken, handleModeChange }: ProfileProps) {
  const { t } = useTranslation();
  const { userData } = useUserData();
  const { companyData } = useCompanyData();
  const { companyConfiguration } = useCompanyConfigurationData();
  const [learningInfo, setLearningInfo] = useState<LearningInfo | undefined | null>(null);
  const [learningObjectives, setLearningObjectives] = useState<Record<string, string> | undefined | null>(null);
  const [newUser, setNewUser] = useState<boolean | undefined>(userData?.newUser || userData?.created);
  const [assessments, setAssessments] = useState<AssessmentsItem[] | undefined | null>(null);
  const [customQuizCompleted, setCustomQuizCompleted] = useState<TrainingsCompleted[]>();

  useMemo(() => {
    if (accessToken && userData) {
      (async () => {
        const [data, responseStatus] = await fetchFromBackend("menu/learning");
        if (responseStatus === 200) {
          setLearningInfo(data);
        } else {
          setLearningInfo(undefined);
        }
      })();
    }
  }, [accessToken, userData]);

  useMemo(() => {
    if (accessToken) {
      (async () => {
        const [data, responseStatus] = await fetchFromBackend("learningObjective?" + new URLSearchParams({ lang: userData?.userLanguage ?? "en" }));
        if (responseStatus === 200) {
          setLearningObjectives(data);
        } else {
          setLearningObjectives(undefined);
        }
      })();
    }
  }, [accessToken, userData?.userLanguage]);

  useMemo(() => {
    if (accessToken && companyConfiguration?.showAssessments) {
      (async () => {
        const [data, responseStatus] = await fetchFromBackend("assessment");
        if (responseStatus === 200) {
          setAssessments(data);
        } else {
          setAssessments(undefined);
        }
      })();
    }
  }, [accessToken, companyConfiguration?.showAssessments]);

  useMemo(() => {
    if (accessToken) {
      (async () => {
        const [data, responseStatus] = await fetchFromBackend("user/customQuizCompleted");
        if (responseStatus === 200) {
          setCustomQuizCompleted(data);
        } else {
          setCustomQuizCompleted(undefined);
        }
      })();
    }
  }, [accessToken]);

  return (
    <>
      {newUser && <WelcomeModalUser handleModeChange={handleModeChange} setNewUser={setNewUser} accessToken={accessToken} user={userName} />}
      {companyData && !userData && (
        <LoaderHelper header={t("Profile.LoadingMsg.loading")} text={t("Profile.LoadingMsg.loadingProfile")} showLoader={true} />
      )}
      {companyData && !newUser && userData && (
        <div className="profileView">
          <Progress learningInfo={learningInfo} learningObjectives={learningObjectives} handleModeChange={handleModeChange} />
          <Trainings learningObjectives={learningObjectives} customQuizCompleted={customQuizCompleted} />
          <Badges learningInfo={learningInfo} handleModeChange={handleModeChange} />
          <UserCard cyberCoachGroups={learningInfo?.availableLearningPaths} userName={userName} userPicture={userImg} accessToken={accessToken} />
          <Assessments assessments={assessments} setAssessments={setAssessments} />
        </div>
      )}
    </>
  );
}
