import { getAccessToken } from "./microsoftAuth";
import { getClient, CLIENT_MICROSOFT_WEB, CLIENT_TEAMS, CLIENT_SLACK } from "./authHelpers";

/**
 * Helper function to make a request to the backend.
 * @param {string} endpoint HTTP path for the request
 * @param {string} [method] HTTP-method, defaults to GET
 * @param {Object} [body] Optional JSON body for the request
 * @returns {Promise<[*,number|null]>} Tuple consisting of the return value and the HTTP status code
 */
export async function fetchFromBackend(endpoint, method, body) {
  try {
    await verifyBackendAccess();
    const response = await fetch("api/" + endpoint, {
      headers: {
        "Content-Type": "application/json",
      },
      method: method || "GET",
      body: body ? JSON.stringify(body) : null,
    });
    if (response.status === 204) {
      return [null, response.status];
    } else {
      const backupRequest = response.clone();
      try {
        const responseData = await response.json();
        return [responseData, response.status];
      } catch {
        //some endpoints returns just a value. Try with that before throwing errors
        const responseData = await backupRequest.text();
        return [responseData, response.status];
      }
    }
  } catch (err) {
    console.log("Backend call to api/" + endpoint + " failed. " + err);
    return [false, null];
  }
}

/**
 * Checks if user is permitted to make backend calls
 * If accesstoken not available from cookie then request backend for a new cookie
 * @returns {Promise<boolean>}
 */
export async function verifyBackendAccess() {
  const accessToken = getCookie("token");
  if (accessToken) {
    return true;
  } else {
    return await requestBackendAccess();
  }
}

//should some sort of react-cookie library be used instead
/**
 * Gets the value of a cookie based on the cookie name
 * @param {string} cookieName cookie name
 * @returns {string|undefined} cookieValue
 */
function getCookie(cookieName) {
  let name = cookieName + "=";
  let ca = document.cookie.split(";");
  for (const element of ca) {
    let c = element;
    while (c.startsWith(" ")) {
      c = c.substring(1);
    }
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

/**
 * Requests backend access
 * @returns {Promise<boolean>} cookieValue
 */
async function requestBackendAccess() {
  try {
    //we need to know which provider first
    const client = await getClient();
    if (client === CLIENT_MICROSOFT_WEB || client === CLIENT_TEAMS) {
      return await getBackendAccessForMicrosoft();
    } else if (client === CLIENT_SLACK) {
      return await getBackendAccessForSlack();
    } else {
      throw new Error("Unkown client");
    }
  } catch (error) {
    throw new Error("Failed to acquire backend access");
  }
}

/**
 * Requests backend access
 * @returns {Promise<boolean>} cookieValue
 */
async function getBackendAccessForMicrosoft() {
  //if microsoft we first need to ask for an app token
  const scopeOptions = { scopes: [process.env.REACT_APP_IDENTIFIER + "/access_as_user"] };
  const frontendToken = await getAccessToken(scopeOptions);
  //now let's validate the app token
  const response = await fetch("/auth", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + frontendToken,
    },
  });
  if (response.status === 200) {
    return true;
  } else {
    throw new Error("Unauthenticated");
  }
}

/**
 * Requests backend access
 * @returns {Promise<boolean>} cookieValue
 */
async function getBackendAccessForSlack() {
  const response = await fetch("/auth", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 200) {
    return true;
  } else {
    throw new Error("Unauthenticated");
  }
}
