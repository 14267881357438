import { Form, Text, Flex, FormRadioGroup, FormTextArea, FormDatepicker, FormDropdown, FormButton } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";

function BugReportContent({
  submitted,
  submitting,
  submitError,
  bugContent,
  setBugContent,
  submitFeedback,
}: {
  submitted: boolean;
  submitting: boolean;
  submitError: boolean;
  bugContent: any;
  setBugContent: any;
  submitFeedback: any;
}) {
  const { t } = useTranslation();
  const timeDropDown = [
    "00:00-01.00",
    "01:00-02.00",
    "02:00-03.00",
    "03:00-04.00",
    "04:00-05.00",
    "05:00-06.00",
    "06:00-07.00",
    "07:00-08.00",
    "08:00-09.00",
    "09:00-10.00",
    "10:00-11.00",
    "11:00-12.00",
    "12:00-13.00",
    "13:00-14.00",
    "14:00-15.00",
    "15:00-16.00",
    "16:00-17.00",
    "17:00-18.00",
    "18:00-19.00",
    "19:00-20.00",
    "20:00-21.00",
    "21:00-22.00",
    "22:00-23.00",
    "23:00-24.00",
  ];

  return (
    <>
      {submitted && (
        <Flex column gap="gap.medium" styles={{ width: "100%" }} hAlign="start">
          <Text content={t("BugReport.thankYou2")} />
        </Flex>
      )}
      {submitError && (
        <Flex column gap="gap.medium" styles={{ width: "100%" }} hAlign="start">
          {/* TODO: add translations */}
          <Text weight="bold" content={"Something went wrong :("} />
          <Text content={"If reporting bugs is a bug it is indeed very bad."} />
          <Text content={"We are monitoring the service constantly and this error will for sure catch our attention."} />
          <Text content={"You can also reach out to the CyberCoach team using some other challenge while we are fixing this. Apologies."} />
        </Flex>
      )}
      {!submitting && !submitted && (
        <Form
          className="bugReportForm"
          styles={{ gap: "1.5rem", marginTop: "1rem" }}
          onSubmit={() => {
            submitFeedback(bugContent);
          }}
        >
          <Text content={t("BugReport.bugMessage")} />
          <Flex column gap="gap.smaller">
            <Text weight="bold" content={t("BugReport.bugLocation")} />
            <FormRadioGroup
              vertical
              checkedValue={bugContent.bugLocation}
              items={[
                {
                  name: "bot",
                  key: "bot",
                  label: t("BugReport.botLabel"),
                  value: "bot",
                  checked: bugContent.bugLocation === "bot",
                  onClick: () => setBugContent({ ...bugContent, bugLocation: "bot" }),
                },
                {
                  name: "profile",
                  key: "profile",
                  label: t("Header.profileButton"),
                  value: "profile",
                  checked: bugContent.bugLocation === "profile",
                  onClick: () => setBugContent({ ...bugContent, bugLocation: "profile" }),
                },
                {
                  name: "dashmin",
                  key: "dashmin",
                  label: t("Header.adminModeButton"),
                  value: "dashmin",
                  checked: bugContent.bugLocation === "dashmin",
                  onClick: () => setBugContent({ ...bugContent, bugLocation: "dashmin" }),
                },
              ]}
            />
          </Flex>
          {bugContent.bugLocation && (
            <>
              <Flex column gap="gap.smaller">
                <Text weight="bold" content={t("BugReport.describeError")} />
                <Text size="small" content={t("BugReport.describeInfo")} />
                <FormTextArea
                  required
                  value={bugContent.errorDesc}
                  onInput={(e: React.FormEvent<HTMLTextAreaElement>) =>
                    setBugContent({ ...bugContent, errorDesc: (e.target as HTMLTextAreaElement).value })
                  }
                  fluid
                  id="errorDesc"
                  rows={5}
                />
              </Flex>
              <Flex column gap="gap.smaller">
                <Text weight="bold" content={t("BugReport.whenError")} />
                <Text size="small" content={t("BugReport.whenInfo")} />
                <Flex gap="gap.small">
                  <FormDatepicker
                    label={t("BugReport.dateSelect") ?? ""}
                    maxDate={new Date()}
                    inputPlaceholder={t("BugReport.dateSelect") ?? ""}
                    selectedDate={bugContent.bugDate}
                    onDateChange={(e, v) => setBugContent({ ...bugContent, bugDate: v?.value as Date })}
                  />
                  <FormDropdown
                    fluid
                    label={t("BugReport.timeSelect")}
                    items={timeDropDown}
                    getA11ySelectionMessage={{
                      onAdd: (item) => {
                        setBugContent({ ...bugContent, bugTime: item as string });
                        return "Time selected";
                      },
                    }}
                  />
                </Flex>
              </Flex>
            </>
          )}
          <FormButton content={t("Feedback.submitButton")} key="submit" primary />
        </Form>
      )}
    </>
  );
}

export default BugReportContent;
