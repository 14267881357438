import React from "react";
import { Dropdown } from "@fluentui/react-northstar";
import { CyberCoachGroup, CyberCoachGroups, DropdownValue, LearningPathPickerProps } from "../../utils/types/common";

export default function LearningPathPicker({ handleLearningPathChange, selectedPath, cyberCoachGroups, manageMode }: LearningPathPickerProps) {
  let dropDownValues = (cyberCoachGroups as CyberCoachGroup[])?.map((group) => ({
    key: group?.groupId,
    header: group?.groupName,
    selected: group?.groupId === selectedPath?.groupId,
    active: group?.groupId === selectedPath?.groupId,
  }));
  if (manageMode) {
    dropDownValues = (cyberCoachGroups as CyberCoachGroups[])?.map((group) => ({
      key: group?.groupId,
      header: group.groupTranslations[0].groupName,
      selected: group?.groupId === selectedPath?.groupId,
      active: group?.groupId === selectedPath?.groupId,
    }));
  }

  const defaultValue = dropDownValues?.find((value) => value.key === selectedPath?.groupId);

  function pickLearningPath(item: DropdownValue) {
    handleLearningPathChange(item.key);
    return "Learning path changed";
  }
  return (
    <Dropdown
      disabled={!dropDownValues}
      inverted
      fluid
      styles={{ width: "100%" }}
      checkable
      items={dropDownValues}
      value={defaultValue}
      defaultValue={defaultValue}
      getA11ySelectionMessage={{
        onAdd: (item) => pickLearningPath(item as DropdownValue),
      }}
    />
  );
}
