import React, { useState, useEffect } from "react";
import {
  Form,
  FormInput,
  FormButton,
  Flex,
  Text,
  Loader,
  Dialog,
  AcceptIcon,
  Button,
  Input,
  ErrorIcon,
  Alert,
  Tooltip,
  QuestionCircleIcon,
} from "@fluentui/react-northstar";
import { fetchFromBackend } from "../lib/makeBackendCall";
import { LanguagePicker } from "../elements/LanguagePicker";
import { customLogout } from "../lib/authHelpers";
import { Link } from "react-router-dom";

export function EnterLicense(props) {
  const tenant = props.tenant;
  //if Slack we also check the teamName this should be shown to users to avoid confusion if using wrong workspace
  const teamName = props.teamName;
  //this is what was given as queryParam from Slack app. If null we cannot make comparison
  const queryTeamId = props.queryTeamId;
  //here is the comparison. If they do not match user has logged in with wrong workspace
  const loggedInWithCorrectWorkSpace = (queryTeamId && queryTeamId === tenant) || !queryTeamId || queryTeamId === "null";

  const [showDialog, setShowDialog] = useState(true);
  const [validLicense, setValidLicense] = useState(null);
  const [loading, setLoading] = useState(null);
  const [attempts, setAttempts] = useState(null);
  const [modal, setModal] = useState(null);

  const [companyData, setCompanyData] = useState();
  const [employeeError, setEmployeeError] = useState(null);
  const [employeeValue, setEmployeeValue] = useState("");

  const [defaultLanguage, setDefaultLanguage] = useState("en");
  const [orgLanguages, setOrgLanguages] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (defaultLanguage) {
      setAlert();
    }
  }, [defaultLanguage]);

  async function updateCompanyData() {
    setLoading(true);
    const newCompanyData = { ...companyData };
    newCompanyData.companyName = document.getElementById("organisation-name").value;
    newCompanyData.logoUrl = document.getElementById("logo-Url").value;
    newCompanyData.defaultLanguage = defaultLanguage;
    if (newCompanyData.orgsLanguageChanged === true) {
      newCompanyData.orgLanguages = companyData.orgLanguages.map((lang) => lang.languageCode);
    } else {
      newCompanyData.orgLanguages = null;
    }
    newCompanyData.employeeAmount = parseInt(document.getElementById("employees").value);

    try {
      await fetchFromBackend("company", "PUT", newCompanyData);
      newCompanyData.orgLanguages = companyData.orgLanguages;
      delete newCompanyData.orgsLanguageChanged;

      setOrgLanguages(null);
      setCompanyData(newCompanyData);
    } catch (err) {
      console.error("Failed to update Company Profile");
    }
    setLoading(false);
  }

  useEffect(() => {
    if (orgLanguages) {
      let newOrgLanguageArr = [];
      if (orgLanguages.length > 0) {
        //orgLanguages is here just an array of languageCodes. We need also name for rendering values neatly
        for (const language of companyData.supportedLanguages) {
          if (orgLanguages.includes(language.languageCode)) {
            newOrgLanguageArr.push(language);
          }
        }
      }

      if (!orgLanguages.includes(defaultLanguage) && newOrgLanguageArr.length > 0) {
        setCompanyData({ ...companyData, orgLanguages: newOrgLanguageArr, orgsLanguageChanged: true });
        if (defaultLanguage) {
          setAlert("Default language removed from supported languages. Please choose a new language as default.");
        }
        setDefaultLanguage(null);
      } else if (newOrgLanguageArr.length === 0) {
        setCompanyData({ ...companyData, orgLanguages: newOrgLanguageArr, orgsLanguageChanged: true });
        setAlert("No supported language chosen. Please choose the languages your organization supports and then pick a default language");
        setDefaultLanguage(null);
      } else {
        setCompanyData({ ...companyData, orgLanguages: newOrgLanguageArr, orgsLanguageChanged: true });
        setAlert();
      }
      setOrgLanguages(null);
    }
  }, [orgLanguages, companyData, setCompanyData, defaultLanguage]);

  function formValidation(item) {
    if (isNaN(item) || parseInt(item) < 0 || parseFloat(item) !== parseInt(item)) {
      setEmployeeError(true);
    } else {
      setEmployeeError(false);
    }
  }

  //check if someone with this session has attempted and failed already
  useEffect(() => {
    if (attempts === null) {
      (async () => {
        const tries = await fetch("/attempts");
        const data = await tries.json();
        setAttempts(data.attempts);
      })();
    }
  }, [attempts]);

  async function enterLicense() {
    const licenseKey = document.getElementById("licenseInput").value;
    if (!licenseKey) {
      setValidLicense("");
    } else {
      setLoading(true);
      try {
        const [data, statusCode] = await fetchFromBackend("license/" + licenseKey, "PUT");
        if (statusCode === 200) {
          //now get the companyData (which is empty)
          const [data] = await fetchFromBackend("company");
          setCompanyData({ ...data, companyName: "" });
          setValidLicense(true);
        } else {
          setAttempts(data.attempts);
          setValidLicense(false);
          throw new Error("Invalid license code");
        }
        setLoading(false);
      } catch (err) {
        console.log("Couldn't validate license code");
        setLoading(false);
      }
    }
  }
  return (
    <Dialog
      open={showDialog}
      styles={{ maxHeight: "100%", width: "50%" }}
      content={
        <Flex column gap="gap.large" styles={{ marginTop: "1rem" }}>
          {!modal && (
            <>
              {attempts === null && <Loader />}
              {attempts !== null && loggedInWithCorrectWorkSpace && (
                <>
                  <Flex column gap="gap.small">
                    <Text>Hi!</Text>
                    <Text>
                      It appears your organization has not yet taken CyberCoach into use. If you have purchased CyberCoach and have the license code
                      please enter it here.
                    </Text>
                    {teamName && (
                      <Flex row="true" gap="gap.medium" space="between" vAlign="center">
                        <Text styles={{ width: "80%" }}>
                          You are signed in with Slack Workspace <b>{teamName}</b>. If you don&apos;t think you should be seeing this view check with
                          your IT-admins that you are using the workspace in which CyberCoach is installed. The correct workspace is the one where you
                          also see CyberCoach as a Slack application.
                        </Text>
                        <Button styles={{ width: "20%" }} secondary onClick={() => customLogout()}>
                          Back to Login
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                  <Flex column gap="gap.small" hAlign="start">
                    <Flex row="true" gap="gap.small" styles={{ width: "80%" }}>
                      <Input
                        fluid
                        id="licenseInput"
                        styles={{ width: "80%" }}
                        placeholder={"Enter license code here"}
                        disabled={loading || attempts >= 3 || validLicense}
                      />
                      <Button primary content={"Submit"} onClick={() => enterLicense()} loading={loading} disabled={attempts >= 3 || validLicense} />
                    </Flex>
                    {validLicense && (
                      <>
                        <Alert
                          icon={<AcceptIcon />}
                          success
                          content="License valid"
                          dismissible
                          dismissAction={{ "aria-label": "close" }}
                          styles={{ width: "64%" }}
                        />
                        <Button primary content={"Continue"} onClick={() => setModal("Company")} />
                      </>
                    )}
                    {validLicense === false && (
                      <Alert
                        icon={<ErrorIcon />}
                        warning
                        content={"The license number you entered is invalid. You have " + (3 - attempts) + " attempts left."}
                        styles={{ width: "64%" }}
                      />
                    )}
                    {validLicense === "" && (
                      <Alert
                        icon={<ErrorIcon />}
                        warning
                        content="License number empty. Please fill in your license number."
                        styles={{ width: "64%" }}
                      />
                    )}
                    {attempts >= 3 && (
                      <Alert
                        icon={<ErrorIcon />}
                        error
                        content="You have exceeded the amount of attempts for entering the license number. Please try again later."
                        styles={{ width: "64%" }}
                      />
                    )}
                  </Flex>
                </>
              )}
            </>
          )}
          {!loggedInWithCorrectWorkSpace && (
            <Flex>
              <Text>
                You have logged in with a workspace that doesn&apos;t have CyberCoach installed. Please verify you logged in with the correct
                workspace. The previous Slack login screen has a workspace selector top right of the screen.
              </Text>
              <Button primary onClick={() => customLogout()}>
                Back to Login
              </Button>
            </Flex>
          )}
          {modal === "Company" && (
            <>
              <Text>
                Your organisation is now ready to use CyberCoach. Before taking it into use we would advice you to fill in some basic information
                about your organisation. You can also do this later at anytime by visiting the configure section in the admin dashboard.
              </Text>
              <Flex
                column
                gap="gap.smaller"
                vAlign="center"
                space="evenly"
                styles={{ width: "100%", height: "80%", marginTop: "0.5em", marginBottom: "0.5em" }}
              >
                {loading && <Loader></Loader>}
                {!loading && (
                  <Form
                    onSubmit={() => {
                      updateCompanyData();
                    }}
                    styles={{ width: "50%" }}
                  >
                    <FormInput
                      fluid
                      label="Organisation Name"
                      name="organisationName"
                      id="organisation-name"
                      defaultValue={companyData.companyName}
                      required
                      showSuccessIndicator={false}
                      placeholder={"Type name here"}
                    />
                    <FormInput
                      fluid
                      label="Logo Url"
                      name="logoUrl"
                      id="logo-Url"
                      defaultValue={companyData.logoUrl}
                      showSuccessIndicator={false}
                      placeholder={"Optional link to company logo"}
                    />
                    <FormInput
                      label="Employees:"
                      name="employees"
                      id="employees"
                      value={employeeValue}
                      required
                      error={employeeError}
                      errorMessage={employeeError ? "Must be an integer above 0" : ""}
                      aria-invalid={employeeError}
                      showSuccessIndicator={!employeeError}
                      placeholder={employeeValue}
                      onInput={(item) => formValidation(item.target.value) + setEmployeeValue(item.target.value)}
                      inline
                    />
                    <Flex column styles={{ width: "100%" }}>
                      <Tooltip
                        trigger={<Text content="Organization Languages" />}
                        content={"Choose which languages will be available for your organization"}
                      />
                      <LanguagePicker
                        inverted={false}
                        mode={"SetOrgLanguages"}
                        companyData={companyData}
                        defaultSelected={
                          companyData?.orgLanguages?.length ? companyData?.orgLanguages?.map((lang) => lang.languageCode) : [defaultLanguage]
                        }
                        handleLanguageArrayChange={setOrgLanguages}
                      />
                    </Flex>
                    <Flex column styles={{ width: "100%" }}>
                      <Tooltip trigger={<Text content="Default Language" />} content={"Choose the language users will get by default"} />
                      <LanguagePicker
                        inverted={false}
                        companyData={companyData}
                        disabled={!companyData.orgLanguages || companyData.orgLanguages.map((lang) => lang.languageCode).length === 0}
                        defaultSelected={defaultLanguage}
                        handleLanguageChange={setDefaultLanguage}
                      />
                      {alert && <Alert icon={<ErrorIcon />} danger content={alert} />}
                    </Flex>
                    <Flex row="true" gap="gap.medium" styles={{ width: "80%" }}>
                      <FormButton fluid styles={{ width: "40%" }} content="Submit" />
                      <Button
                        fluid
                        styles={{ width: "60%" }}
                        primary
                        content="I'll do it later"
                        onClick={function () {
                          setShowDialog(false);
                          window.location.reload();
                        }}
                      />
                    </Flex>
                    <Flex column gap="gap.smaller" hidden={!companyData.companyName && !loading} styles={{ width: "80%" }}>
                      <Alert icon={<AcceptIcon />} success content="Values saved" dismissible dismissAction={{ "aria-label": "close" }} />
                      <Button
                        primary
                        fluid
                        content={"Continue to Dashboard"}
                        onClick={function () {
                          setShowDialog(false);
                          window.location.reload();
                        }}
                      />
                    </Flex>
                  </Form>
                )}
              </Flex>
            </>
          )}
        </Flex>
      }
      header={<ModalHeader modal={modal} teamName={teamName} />}
    />
  );
}

function ModalHeader(props) {
  const modal = props.modal;
  const teamName = props.teamName;

  let headerText = "Organization " + (teamName ? teamName : "") + " not known by CyberCoach";
  if (modal && modal === "Company") {
    headerText = "Configure your organization";
  }

  return (
    <Flex row="true" hAlign="stretch" space="between" vAlign="end" gap="gap.large">
      <Text content={headerText} size="larger" />
      <Link to="https://support.cybercoach.com/en/welcome-to-cybercoach/marketplace-support" target="_blank" rel="noopener noreferrer">
        <Button icon={<QuestionCircleIcon />} content={"Help"} size="small" />
      </Link>
    </Flex>
  );
}
